#page-coming {
    padding-top: 100px !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    overflow: hidden;
    background: black;
    width: 100%;
    padding: 50px;
    padding-bottom: 0;
    flex-direction: column;
    height: auto;

    @media screen and (max-width: $mobile) {
        padding-bottom: 0;
    }

    h1 {
        color: white;
        font-size: 9vw;
        letter-spacing: -0.03em;
        margin-bottom: 0;

        @media screen and (max-width: $mobile) {
            margin-bottom: 40px;
        }

        span {
            display: block;

            @media screen and (max-width: $mobile) {
                display: inline;
            }
        }

        @media screen and (max-width: $mobile) {
            font-size: 12vw;
        }

    }

    ul.upcoming {
        width: 100%;
        display: flex;
        margin-top: 40px;
        
        @media screen and (max-width: $mobile) {
            flex-direction: column;
        }

        &.in-view {
            li:nth-child(1) {
                opacity: 1;
                transform: translateY(0);
                transition: .4s opacity ease, .4s transform ease;
            }

            li:nth-child(2) {
                opacity: 1;
                transform: translateY(0);
                transition: .4s .5s opacity ease, .4s .5s transform ease;
            }

            li:nth-child(3) {
                opacity: 1;
                transform: translateY(0);
                transition: .4s 1s opacity ease, .4s 1s transform ease;
            }

            li:nth-child(4) {
                opacity: 1;
                transform: translateY(0);
                transition: .4s 1.5s opacity ease, .4s 1.5s transform ease;
            }

            li:nth-child(5) {
                opacity: 1;
                transform: translateY(0);
                transition: .4s 2s opacity ease, .4s 2s transform ease;
            }
        }

        li { 
            color: white;
            font-size: 1.5vw;
            line-height: 1.2em;
            letter-spacing: -0.03em;
            margin-right: 20px;
            opacity: 0;
            position: relative;
            transform: translateY(30%);

            @media (max-width: $desktop) {
                font-size: 20px;
            }

            
            @media (max-width: $mobile) {
                margin-bottom: 40px;
                width: 80%;
            }

            &.divider {
                display: flex;
                justify-content: center;

                svg {
                    width: 50px;
                }

                @media (max-width: $mobile) {
                   width: 25px;
                }
            }
        }
    }
}