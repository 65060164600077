#page-welcome {
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
    margin-top: 51px;
    padding-top: 56.25%;

    .btn-toggleMute {
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 99;
        background: none;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
        mix-blend-mode: difference;
        font-weight: 300;

        &:hover {
            opacity: .7;
        }
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    h1 {
        color: white;
        font-size: 9vw;
        letter-spacing: -0.03em;
        margin-bottom: 0;


        em {
            margin-right: 5px;
        }

        @media screen and (max-width: $mobile) {
            margin-bottom: 40px;
        }

        span {
            display: block;

            @media screen and (max-width: $mobile) {
                display: inline;
            }
        }

        @media screen and (max-width: $tablet) {
            font-size: 12vw;
        }

        @media screen and (max-width: $mobile) {
            font-size: 16vw;
        }

    }
}