@font-face {
    font-family: 'UniversalSans-400';
    src: url('static/fonts/UniversalSans-400.woff2') format('woff2'),
        url('static/fonts/UniversalSans-400.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1, h2, h3, h4 {
    font-family: 'UniversalSans-400', system-ui, sans-serif;
    font-weight: normal;
    margin: 0;
    letter-spacing: -0.08em;
    line-height: 1em;
}

h2 {
    font-size: 4em;
    margin-bottom: 0.3em;
    font-weight: normal;
}

p {
    font-family: 'UniversalSans-400', system-ui, sans-serif;
    font-weight: normal;
    margin: 0;
    font-size: 0.9em;
    line-height: 1.4em;
    margin-bottom: 1.2em;

    &:last-child {
        margin-bottom: 0;
    }
}