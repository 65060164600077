#footer {
    z-index: 9999;
    background: rgba(0, 0, 0, 1);
    transition: 0.8s ease;
    position: relative;
    z-index: 999;

    footer {
        display: flex;
        color: white;
        padding: 50px;
        justify-content: space-between;
        height: 100%;
        flex-direction: column;
        

        @media (max-width: $tablet) {
            flex-direction: column;
        }

        .headline {
            background: black;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            padding-top: 100px;
            padding-bottom: 100px;

            h1 {
                color: white;
                font-size: 9vw;
                letter-spacing: -0.03em;
                margin-bottom: 0;
                margin-left: -5px;
        
                @media screen and (max-width: $mobile) {
                    margin-bottom: 40px;
                }
        
                span {
                    display: block;
        
                    @media screen and (max-width: $mobile) {
                        display: inline;
                    }
                }
        
                @media screen and (max-width: $mobile) {
                    font-size: 12vw;
                }

                span {
                    @media screen and (min-width: $desktop) {
                        &:nth-child(4) {
                            position: relative;
                            left: -0.07em;
                        }
                    }

                    @media screen and (max-width: $mobile) {
                        &:nth-child(4) {
                            position: relative;
                            left: 0px;
                        }
                    }
        
                }
        
            }

            button {
                padding: 10px 0;
                border: none;
                border-radius: 0;
                background: none;
                cursor: pointer;
                margin-top: 40px;
                color: white; 
                font-size: 25px;   
                font-weight: 300;
                border-bottom: 1px solid white;
           

                &:hover {
                    color: #7DD1FF;
                    border-color: #7DD1FF;
                }
            }
        }

        .footer-items {
            display: flex;
            margin-top: 30px;
            width: 50%;
            justify-content: flex-end;

            @media (max-width: $mobile) {
                width: 100%;
                justify-content: space-between;
            }

            ul {
                margin-right: 20px;
            }

            ul:last-child {
                width: auto;
                margin-right: 0;
            }
        }

        #sub-thanks {
            display: none;
        }

        .logo {
            width: 50px;
        }

        a {
            color: white;
            text-decoration: none;

            &:hover {
                color: #7DD1FF;

                span {
                    background: #7DD1FF !important;
                }
            }
        }

        .left {
            width: 100%;
            display: flex;
            border-top: 1px solid #333;
            margin-top: 40px;
            padding-top: 20px;

            @media (max-width: $tablet) {
                width: 100%;
                justify-content: space-between;
                margin-bottom: 40px;
            }

            a{
                font-weight: 400;
            }

            h1 {
                color: white;
                font-size: 9vw;
                letter-spacing: -0.03em;
                margin-bottom: 0;
        
                @media screen and (max-width: $mobile) {
                    margin-bottom: 40px;
                }
        
                span {
                    display: block;
        
                    @media screen and (max-width: $mobile) {
                        display: inline;
                    }
                }
        
                @media screen and (max-width: $mobile) {
                    font-size: 12vw;
                }
        
            }
        
            > div {
                &:first-child {
                    width: 100%;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    @media screen and (max-width: $mobile) {
                        display: block;
                    }
                }
            }

            .joinus {
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
                font-size: 14px;
            }

            ul {
                li {
                    margin-bottom: 4px;
                    font-weight: 400;
                    font-size: 14px;

                    @media screen and (max-width: $mobile) {
                        font-size: 12px;
                        
                    }

                    &:nth-child(even) {
                        span {
                            border-radius: 50%
                        }
                    }

                    span {
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        background: white;
                        margin-right: 5px;
                    }
                }
            }
        }

        .right {
            max-width: 28%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            @media (max-width: $tablet) {
                max-width: 100%;
            }

            .legal {
                font-size: .7em;
                text-align: right;
                margin-top: 30px;

                @media (max-width: $tablet) {
                    margin: 30px 0;
                }
            }

            p {
                font-size: 1em;
            }

            a.terms {
                font-size: .8em;
                display: block;
                margin-top: 15px;
                display: none;
            }

            .inputField {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                ::placeholder {
                    color: white;
                    opacity: 1; /* Firefox */
                  }
                  
                ::-ms-input-placeholder { /* Edge 12 -18 */
                color: white;
                }

                input[type="email"] {
                    background: #333;
                    color: white;
                    padding: .8em;
                    border: none;
                    width: 80%;
                    font-family: 'UniversalSans-400', system-ui, sans-serif;
                }

                button[type="submit"] {
                    background: #7DD1FF;
                    color: black;
                    padding: .8em;
                    border: none;
                    width: 20%;
                    cursor: pointer;
                    font-weight: 500;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    span {
                        background: black;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        display: inline-block;
                        left: -5px;
                        position: relative;
                        top: -1px;
                    }

                    em {
                        display: block;
                    }
                }
            }
        }
    }
}