nav {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    align-items: center;
    padding: 10px 20px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;
    background: white;
    font-family: 'UniversalSans-400', system-ui, sans-serif;

    > a {
        display: flex;
        align-items: flex-end;

        span {
            margin-left: 10px;
            margin-top:2px;
            opacity: 1;
            transition: .5s opacity ease-in-out;
            position: relative;
            bottom: -2px;

            &.hidden {
                opacity: 0;
                transition: .5s opacity ease-in-out;
            }
        }
    }

    &.dark {
        background: black;
        svg path {
            fill: white !important;
        }

        .btn-menu { 
            color: white;
        }
    }

    .btn-menu {
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        height: 30px;

        em {
            position: relative;
            bottom: -2px;
        }

        &:hover {
            opacity: 0.5;
        }

        span {
            background: #7DD1FF;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: block;
            margin-right: 5px;
            
        }
    }

    &.is-active {
        .btn-menu {
            opacity: 0;
            pointer-events: none;

            

            &:hover {
                opacity: 0;
            }
        }

        .site-navigation-list  {
            @media (max-width: $tablet) {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                padding: 20px;
                padding-top: 120px; 
                background:rgba(0,0,0,.9);
                justify-content: center;
            }

            li {
                opacity: 1;
                pointer-events: all;
                animation: fadeIn 0.3s 1;
                animation-fill-mode: forwards;

                @media (max-width: $tablet) {
                    &:last-child {
                        a {
                            border-right: none !important;
                        }
                    }
                }

                a {
                    @media (max-width: $tablet) {
                        border: none;
                        width: 100%;
                        text-align: center;
                        font-size: 2rem;
                    }
                }
            }
        }

    }

    a {
        color: var(--text-color);
        text-decoration: none;
        font-weight: normal;
    }

    svg.logo {
        width: 30px;
    }


    .site-navigation-list {
        display: flex;
        
        li {
            opacity: 0;
            pointer-events: none;
            transition: .2s all ease-in-out;

            a {
                padding: .8em 2em;
                display: inline-block;
                border: 1px solid white;
                border-right: none;
                text-transform: uppercase;
                color: white;
                text-decoration: none;
                font-family: 'UniversalSans-400', system-ui, sans-serif !important;

                &:hover {
                    background: white;
                    color: black;
                }
            }

            &:last-child {
                a {
                    border-right: 1px solid white;
                }
            }
        }
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}