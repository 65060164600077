.reimagine-header {
    padding: 80px 40px;
    
    @media screen and (max-width: $mobile) {
        padding-bottom: 0;
        padding-top: 40px;
    }

    h1 {
        color: black;
        font-size: 9vw;
        letter-spacing: -0.03em;
        margin-bottom: 0;

        @media screen and (max-width: $mobile) {
            margin-bottom: 40px;
        }

        span {
            display: block;

            @media screen and (max-width: $mobile) {
                display: inline;
            }
        }

        @media screen and (max-width: $mobile) {
            font-size: 12vw;
        }

    }
}

#page-reimagine {
    padding-top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    padding: 0;
    flex-direction: column;

    @media screen and (min-width: $tablet) {
        flex-direction: row;
    }

    .inner {
        padding: 50px;
        padding-top: 0;
    }

    div.features {

        overflow: hidden;

        @media screen and (max-width: $mobile) {
            br {
                display: none
            }
        }

        a.link-visit {
            color: black;
            text-decoration: none;
            display: inline-block;
            border-bottom: 1px solid black;
        }

        &.in-view {
            h2 {
                opacity: 1;
                transform: translateX(0);
                transition: .4s opacity ease, .4s transform ease;
            }

            li:nth-child(1) {
                opacity: 1;
                transform: translateX(0);
                transition: .4s .5s opacity ease, .4s .5s transform ease;
            }

            li:nth-child(2) {
                opacity: 1;
                transform: translateX(0);
                transition: .4s 1s opacity ease, .4s 1s transform ease;
            }

            li:nth-child(3) {
                opacity: 1;
                transform: translateX(0);
                transition: .4s 1.5s opacity ease, .4s 1.5s transform ease;
            }

            li:nth-child(4) {
                opacity: 1;
                transform: translateX(0);
                transition: .4s 2s opacity ease, .4s 2s transform ease;
            }
        }

        h2 {
            font-size: 20px;
            letter-spacing: -0.03em;
            padding-right: 5em;
            line-height: 1.2em;
            margin: 0 0 40px 0;
            opacity: 0;
            position: relative;
            transform: translateX(50px);

            @media screen and (max-width: $mobile) {
                padding-right: 0 !important;
            }
        }
    
        ul {
            li {
                display: flex;
                margin-bottom: 20px;
                opacity: 0;
                position: relative;
                transform: translateX(50px);
                
    
                span.icon {
                    width: 70px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
    
                    svg {
                        width: 42px;
                        height: 42px;
                    }
                }
    
                label, p {
                    font-size: 20px;
                    font-weight: 200;
                    line-height: 1.2em;
    
                    span {
                        color: #759096;
                    }
                }
            }
        }
    }

    .video-gallery {
        width: 70%;

        @media screen and (max-width: $mobile) {
            width: 100%;    
        }
    }

    .video-js {
        width: 100%;
        padding-top: 56.25%;
    }

    video {
        width: 100%;
        max-width: 100%;
    }

    .gallery-container {
        position: relative;
    }

    .gallery-image {
        background: black;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9;
        background-size: cover;
    }

    .video-gallery-items {
        margin-top: 20px;
        margin-bottom: 80px;
        display: flex;
        padding-right: 40px;

        @media screen and (max-width: $mobile) {
            margin:10px;   
            margin-top: 20px;
            margin-bottom: 20px; 
            padding-right: 0;
            flex-wrap: wrap;
        }        

        li {
            width: 25%;
            margin-right: 10px;
            margin-bottom: 0;
            transition: .4s opacity ease;
            cursor: pointer;
            font-family: 'Uni';

            @media screen and (max-width: $mobile) {
                width: 32.3333%;
                margin-right: 1%;
                margin-bottom: 1%;
            }

            &:hover {
                opacity: .7;
                transition: .4s opacity ease;
            }

            img {
                max-width: 100%;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                width: 100%;
            }
        }
    }
}