@import './style/variables.scss';
@import './style/reset.scss';
@import './style/typography.scss';
@import './style/nav.scss';
@import './style/footer.scss';

@import './style/pages/home.scss';
@import './style/pages/reimagine.scss';
@import './style/pages/coming.scss';
@import './style/pages/videoScroll.scss';
@import './style/pages/terms.scss';


* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
    box-sizing: border-box;
    -webkit-appearance: none;
}


html {
    --color-background: white;
    --padding: 60px;
    --text-color: black;
    background-color: var(--color-background);
    height: 100%;

    @media (max-width: $mobile) {
        --padding: 20px;
    }
}


body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    overflow-x: hidden;
    color: var(--text-color);
    background: var(--color-background);
    font-family: 'UniversalSans-400', system-ui, sans-serif;
    font-weight: normal;
}

.thanks {
    display: none;
    color: white;
    font-size: 2vw;
    margin-top: 40px;

    @media screen and (max-width: $mobile) {
        font-size: 4vw !important;
    }

    &.sent {
        display: block;
    }
}

.signup {
    position: fixed;
    background: black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-200%);
    transition: 0.4s transform cubic-bezier(0.165, 0.84, 0.44, 1);

    @media screen and (max-width: $mobile) {
        padding: 40px;
    }

    &.open {
        transform: translateY(0%);
        transition: 0.4s transform cubic-bezier(0.165, 0.84, 0.44, 1);
    }

   

    .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        font-size: 2vw;
        cursor: pointer;
        background: none;
        border: none;

        &:hover {
            opacity: 0.5;
        }

        svg {
            width: 30px;
            height: 30px;
            fill: white;
            path {
                fill: white;
            }
        }
    }

    form {
        width: 100%;
        max-width: 580px;

        &.sent {
            display: none;
        }

        label {
            color: white;
            font-size: 2vw;

            

            p {
                margin: 0;

                @media screen and (max-width: $mobile) {
                    font-size: 4vw !important;
                }
            }
        }

        input[type="text"], input[type="email"], .input-text {
            width: 100%;
            background: black;
            color: white;
            width: 100%;
            background: #292929;
            border: none;
            padding: 14px;
            margin-bottom: 20px;
            -webkit-appearance: none;
            border-radius: 0;
        }

        .btn-submit-signup {
            background: none;
            color: white;
            font-size: 2vw;
            border: none;
            border-bottom: 1px solid white;
            padding-bottom: 10px;
            cursor: pointer;

            @media screen and (max-width: $mobile) {
                font-size: 4vw !important;
            }

            &:hover {
                color: #7DD1FF;
                border-color: #7DD1FF;
            }
        }
    }
}

main {
    >div {
        min-height: 100vh;
    }
}

.site-wrapper, .site-container {
    min-height: 100vh;

    &.is-leaving {
        opacity: 0;
    }
}


body {
    &.is-locked {
        @media (max-width: $mobile) {
            overflow: hidden;
        }
    }
}

.animator {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    span {
        display: inline-block;
        overflow: hidden;
        padding-bottom: 1%;

        em {
            margin-right: 0.2em;
            transform: translateY(110%);
            display: inline-block;
        }

        &.in {
            em {
                transform: translateY(0);
                transition: 1s transform cubic-bezier(0.075, 0.82, 0.165, 1)
            }
        }
    }
}

h1.animate {
    overflow: hidden;
    transition: 1s transform ease;
    padding-bottom: 1%;

    span {
        display: inline-block;
        overflow: hidden;
        padding-bottom: 1%;

        @media (max-width: $mobile) {
            display: inline;
        }
    }
    
    em {
        position: relative;
        transform: translateY(100%);
        display: inline-block;

        &.in {
            transform: translateY(0);
            transition: 1s transform cubic-bezier(0.075, 0.82, 0.165, 1)
        }
    }
}

.background-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    >div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        animation: pulse 45s ease-in-out infinite;
    }

    @keyframes pulse {
        0% {
         transform: scale(1, 1);
        }
    
        50% {
         transform: scale(1.5, 1.5) rotate(15deg);
        }
    
        100% {
        transform: scale(1, 1);
        }
    }
}

.page {
    z-index: 1;
    position: relative;
    padding: 0 var(--padding);
    height: 100%;
}


canvas.webgl {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
}

.backgroundImage {
    display: none;
}


.preloader {
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    z-index: 1090;
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background: black;
    transition: opacity 0.4s ease;

    img {
        width: 40px;
    }

    &.show {
        opacity: 1;
        transition: opacity 0.4s ease;
    }

    @media screen and (orientation:landscape)
        and (min-device-width: 319px) 
        and (max-device-width: $mobile) {
            display: none;
    }
}
