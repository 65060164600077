#page-video-scroll {

    background: black;

    #set-height {
        display: block;
    }

    #v0 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    
    p font-family helvetica {
        font-size: 24px;
    }

}

.video-scroller {
    background-color: black;
    background-image: url('static/images/sphere-desktop.jpg');
    background-size: cover;
    background-position: bottom center;
    min-height: 100vh;

    @media screen and (max-width: $mobile) {
        min-height: 60vh;
    }
}

.scrolly-video {
    background: black;
}